import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { useMediaQuery } from "react-responsive";

import styles from "./home.module.scss";

interface CustomerServiceProps {
  csImg: FluidObject;
}

export default function CustomerService({ csImg }: CustomerServiceProps) {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <section className={styles.cs}>
      <div className={styles.txt}>
        <h3>
          TIDAK PERLU KHAWATIR <br />
          CHAT TIDAK TERBALAS
        </h3>
        {isDesktop ? (
          <p>
            24 Jam dalam 7 Hari
            <br />
            tim customer service kami yang ramah
            <br />
            selalu siap menjawab semua pertanyaan anda
          </p>
        ) : (
          <p>
            24 Jam dalam 7 Hari tim customer service kami yang ramah selalu siap
            menjawab semua pertanyaan anda
          </p>
        )}
      </div>
      <Img fluid={csImg} alt="customer service" className={styles.csImg} />
    </section>
  );
}
