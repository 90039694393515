import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Img from "gatsby-image";
import ButtonGradient from "./components/ButtonGradient";
import { clientData } from "@utils/static-data";
import { IMultiFile } from "src/types";

import styles from "./home.module.scss";

interface OurClientProps {
  logo: IMultiFile;
}

export default function OurClient({ logo }: OurClientProps) {
  const { search } = useLocation();

  const renderClientList = () => {
    return clientData.map((client, idx) => (
      <div className={styles.clientItem} key={idx}>
        <Img
          fluid={logo.edges[idx].node.childImageSharp.fluid}
          alt={client.img}
          className="h-100 w-100"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    ));
  };

  return (
    <section className={styles.client}>
      <h3>KLIEN KAMI</h3>
      <div className={styles.clientList}>{renderClientList()}</div>
      <Link to={`/klien-kami/${search}`}>
        <ButtonGradient size="sm" className={styles.btn}>
          Lihat Semua
        </ButtonGradient>
      </Link>
    </section>
  );
}
