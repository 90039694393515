import React from "react";
import Layout from "@components/Layout";
import HeaderHero from "./HeaderHero";
import CustomerService from "./CustomerService";
import Project from "./Project";
import OurProduct from "./OurProduct";
import About from "./About";
import OurService from "./OurService";
import Guide from "./Guide";
import OurClient from "./OurClient";
import { HomePageProps } from "src/types";

export default function Home(props: HomePageProps) {
  const {
    heroDesktop,
    heroMobile,
    csImg,
    bgProjectDesktop,
    bgProjectMobile,
    chart,
    products,
    services,
    clientLogo,
  } = props.data;

  return (
    <Layout>
      <HeaderHero
        bgDesktop={heroDesktop.childImageSharp.fluid}
        bgMobile={heroMobile.childImageSharp.fluid}
      />
      <CustomerService csImg={csImg.childImageSharp.fluid} />
      <Project
        chart={chart.childImageSharp.fluid}
        bgProjectDesktop={bgProjectDesktop.childImageSharp.fluid}
        bgProjectMobile={bgProjectMobile.childImageSharp.fluid}
      />
      <OurProduct products={products} />
      <About />
      <OurService services={services} />
      <Guide />
      <OurClient logo={clientLogo} />
    </Layout>
  );
}
