import React from "react";
import Video from "@components/Video";

import styles from "./home.module.scss";

export default function About() {
  return (
    <section className={styles.about}>
      <h3>Siapakah Kami?</h3>
      <div className={styles.video}>
        <Video
          link="https://www.youtube.com/watch?v=epEB_llv9NY"
          quality="sd"
          imgStyle={{ borderRadius: 0 }}
        />
      </div>
      <div className={styles.txt}>
        <p>
          Kami adalah konveksi Seragam Online no.1 di Indonesia. Kami berdiri
          sejak tahun 2014 di Depok, Jawa Barat.
        </p>
        <p>
          Kami mempunyai misi untuk membahagiakan para pekerja dan juga
          komunitas, dengan menciptakan pakaian yang nyaman dikenakan dalam
          kegiatan sehari-hari.
        </p>
      </div>
    </section>
  );
}
