import React from "react";
import { Row, Col } from "react-bootstrap";
import { freeServices } from "@utils/static-data";
import { IMultiFile } from "src/types";

import styles from "./home.module.scss";

interface OurServiceProps {
  services: IMultiFile;
}

const OurService: React.FC<OurServiceProps> = ({ services }) => {
  const renderService = () => {
    let newServices = [];

    for (let i in freeServices) {
      newServices.push(
        <Col key={i} xs={4} md={2} className={styles.serviceItem}>
          <img
            src={services.edges[i].node.publicURL}
            alt={freeServices[i].label}
          />
          <span className={styles.label}>{freeServices[i].label}</span>
          <span>Gratis</span>
        </Col>
      );
    }

    return newServices;
  };

  return (
    <section className={styles.service}>
      <h3>Kami menawarkan berbagai kemudahan untuk anda</h3>
      <Row className={styles.serviceList}>{renderService()}</Row>
    </section>
  );
};

export default OurService;
