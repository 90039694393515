import React from "react";
import Img from "gatsby-image";
import { Row, Col } from "react-bootstrap";
import { productList } from "@utils/static-data";
import { IMultiFile } from "src/types";

import styles from "./home.module.scss";

interface OurProductProps {
  products: IMultiFile;
}

const OurProduct: React.FC<OurProductProps> = ({ products }) => {
  const renderProduct = () => {
    const view = [];

    for (let i in productList) {
      view.push(
        <Col key={i} xs={6} md={2} className={styles.productItem}>
          <Img
            fluid={products.edges[i].node.childImageSharp.fluid}
            className={styles.productImg}
            imgStyle={{ objectFit: "contain" }}
            alt={productList[i].name}
          />
          <span
            className={styles.productName}
            dangerouslySetInnerHTML={{ __html: productList[i].name }}
          />
        </Col>
      );
    }

    return view;
  };

  return (
    <section className={styles.product}>
      <h3>PRODUK KAMI</h3>
      <Row className={styles.productList}>{renderProduct()}</Row>
    </section>
  );
};

export default OurProduct;
