import React from "react";
import { Button } from "react-bootstrap";

import styles from "../home.module.scss";

interface ButtonGradientProps {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
}

export default function ButtonGradient({
  size = "md",
  children,
  className,
}: ButtonGradientProps) {
  return (
    <Button
      className={`${styles.btnGradient} ${styles[size]} ${
        className ? className : ``
      }`}
    >
      {children}
    </Button>
  );
}
