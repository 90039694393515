import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { Row, Col } from "react-bootstrap";
import ButtonGradient from "./components/ButtonGradient";
import Video from "@components/Video";
import { useMediaQuery } from "react-responsive";
import { materialVideos, measurementVideos } from "@utils/static-data";

import styles from "./home.module.scss";

export default function Guide() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const { search, pathname } = useLocation();

  const renderMaterialVideo = (start: number = 0, end: number = 4) => {
    return materialVideos.slice(start, end).map((video, idx) => (
      <Col
        key={idx}
        xs={12}
        md={3}
        className={`${styles.videoListItem} ${styles.material} ${video.class}`}
      >
        <div className={styles.video}>
          <Video
            quality="hq"
            link={video.link}
            imgStyle={{ borderRadius: 0 }}
          />
        </div>
        <p>{video.title}</p>
      </Col>
    ));
  };

  const renderMeasurementVideo = () => {
    return measurementVideos.slice(0, 4).map((video, idx) => (
      <Col
        key={idx}
        xs={12}
        md={3}
        className={`${styles.videoListItem} ${styles.measure} ${video.class}`}
      >
        <div className={styles.video}>
          <Video
            quality="sd"
            link={video.link}
            imgStyle={{ borderRadius: 0 }}
          />
        </div>
        <p>{video.title}</p>
      </Col>
    ));
  };

  return (
    <section className={styles.guide}>
      <div className={`${styles.banner} ${styles.noImg}`}>
        <div className={styles.txt}>
          <h3>Bingung gak bisa nentuin bahan dan ukuran?</h3>
          <p>
            Jangan khawatir, tonton video penjelasan bahan &amp; juga{" "}
            {isDesktop ? <br /> : null} video cara pengukuran di bawah ini
          </p>
        </div>
      </div>
      <div className={styles.guideContent}>
        <div className={styles.guideItem}>
          <p className={styles.title}>Video Penjelasan Bahan</p>
          <Link to={`/bahan/${search}`}>
            <ButtonGradient size="sm" className={styles.btn}>
              Lihat Semua
            </ButtonGradient>
          </Link>
          <Row className={styles.videoList}>
            {pathname.includes("jaket")
              ? renderMaterialVideo(10, 14)
              : renderMaterialVideo()}
          </Row>
        </div>
        <div className={styles.guideItem}>
          <p className={styles.title}>Video Cara Pengukuran</p>
          <Link to={`/standar-ukuran/${search}`}>
            <ButtonGradient size="sm" className={styles.btn}>
              Lihat Semua
            </ButtonGradient>
          </Link>
          <Row className={styles.videoList}>{renderMeasurementVideo()}</Row>
        </div>
      </div>
    </section>
  );
}
