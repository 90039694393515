import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Img from "gatsby-image";
import BgImage from "gatsby-background-image";
import ButtonGradient from "./components/ButtonGradient";
import { FluidObject } from "gatsby-image";

import styles from "./home.module.scss";

interface HeaderHeroProps {
  bgDesktop: FluidObject;
  bgMobile: FluidObject;
  productImg?: FluidObject;
  productPos?: "left" | "right";
  text?: string;
}

export default function HeaderHero({
  bgDesktop,
  bgMobile,
  productImg,
  productPos = "left",
  text = "SERAGAM ANDA ADALAH<br />CERMINAN PERUSAHAAN",
}: HeaderHeroProps) {
  const { search } = useLocation();

  // Art directing image
  const bg = [
    bgMobile,
    {
      ...bgDesktop,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <header className={styles.heroHeader}>
      <BgImage fluid={bg}>
        <div
          className={`${styles.innerHeroContainer} ${styles[productPos]}`}
          style={
            !productImg
              ? {
                  flexDirection: "row-reverse",
                }
              : {}
          }
        >
          {productImg ? (
            <div className={styles.heroProduct}>
              <Img fluid={productImg} alt="product" />
            </div>
          ) : null}
          <div className={styles.textCta}>
            <h1 dangerouslySetInnerHTML={{ __html: text }} />
            <Link to={`/form/${search}`}>
              <ButtonGradient
                size="lg"
                className="text-uppercase font-weight-bold"
              >
                PESAN SEKARANG
              </ButtonGradient>
            </Link>
          </div>
        </div>
      </BgImage>
    </header>
  );
}
